import React from "react";
import useLogicTimeTable from "./logic/useLogicTimeTable";
import Calendar from "../../commonComponents/Calendar/Calendar";
import InputSelect from "../../commonComponents/inputs/InputSelect/InputSelect";
import FormTraining from "./components/forms/FormTraining/FormTraining";
import LoaderAbovePage from "../../commonComponents/loaders/LoaderAbovePage/LoaderAbovePage";

function TimeTablePage() {
    const {
        list,
        openedForm,
        handleSuccesCreateTraining,
        handleSuccesUpdateTraining,
        handleSuccessRemoveTraining,
        trainingsForCalendar,
        handleTrainingCalendarClick,
        handleSelectSlot,
        trainers,
        selectedSlot,
        selectedHall, setSelectedHall, optionsHalls,
        setRangeDate,
        openTrainingPage,
        selectedTraining,
        trainingSettings,
        closeForm
    } = useLogicTimeTable()
    
    return (
        <div className='time-table width__100'>
            <div className="time-table__hall-selector-container">
                <div className="time-table__hall-selector">
                    <InputSelect
                        label={'Зал:'}
                        options={optionsHalls}
                        value={selectedHall}
                        onChange={setSelectedHall}
                    />
                </div>
            </div>
            <Calendar
                arrayEvents={trainingsForCalendar}
                handleSelectEvent={handleTrainingCalendarClick}
                handleSelectSlot={handleSelectSlot}
                handleRangeDateChange={setRangeDate}
            />
            {selectedHall &&
                <FormTraining
                    isOpen={openedForm === 'FormTraining'}
                    closeForm={closeForm}
                    trainers={trainers}
                    initDateOn={selectedSlot?.start}
                    initDateTo={selectedSlot?.end}
                    selectedHall={selectedHall}
                    handleSuccesCreateTraining={handleSuccesCreateTraining}
                    handleSuccesUpdateTraining={handleSuccesUpdateTraining}
                    handleSuccessRemoveTraining={handleSuccessRemoveTraining}
                    selectedTraining={selectedTraining}
                    openTrainingPage={openTrainingPage}
                    trainingSettings={trainingSettings}
                />}
            <LoaderAbovePage
                isOpen={openedForm === 'LoaderAbovePage'}
            />
        </div>
    )
}

export default TimeTablePage;