import React from "react";
import FormAdd from "../../../../../../../../../../commonComponents/forms/FormAdd/FormAdd";
import InputText from "../../../../../../../../../../commonComponents/inputs/InputText/InputText";
import useLogicFormAddorUpdateTrainers from "./logic/useLogicFormAddorUpdateTrainers"
import InputSelect from "../../../../../../../../../../commonComponents/inputs/InputSelect/InputSelect";
import FormPaymentSetting from "../FormPaymentSetting/FormPaymentSetting";
import ButtonFileUpload from "../../../../../../../../../../commonComponents/buttons/ButtonFileUpload/ButtonFileUpload.tsx";

function FormAddorUpdateTrainers({
    trainer, // если передан то автоматически редактирование
    textButtonSubmit, // текст кнопки сабмита
    handleSuccessAdd, // успешное выполнение
    handleSuccessUpdate,
}) {

    const {
        nameTrainer,
        subnameTrainer,
        number,
        email,
        setAvatar,
        setDataPayment,
        setValidDataPayment,
        validMessage,
        setValidMessage,
        isSubmitDisabled,
        handleChangeName,
        handleChangeSubname,
        handleChangeNumber,
        handleChangeEmail,
        handleSubmit,
        isLoading
    } = useLogicFormAddorUpdateTrainers({ handleSuccessAdd, handleSuccessUpdate, trainer });

    return (
        <FormAdd
            onSubmit={handleSubmit}
            text={!trainer ? 'Добавить тренера' : 'Редактировать'}
            textButtonSubmit={textButtonSubmit}
            validationOptions={isSubmitDisabled} // дисейблить сабмит если true
            textForFill={validMessage}
            cleanTextForFill={() => setValidMessage()}
            isLoading={isLoading}
            buttonUnderText={validMessage}
            children={
                <>
                    <InputText
                        inputValue={nameTrainer}
                        setInputChange={handleChangeName}
                        placeholder={"Введите имя"}
                        //bottomText={validationMassegeName}
                        label={"Имя"}
                    />
                    <InputText
                        inputValue={subnameTrainer}
                        setInputChange={handleChangeSubname}
                        placeholder={"Введите фамилию"}
                        //bottomText={validationMassegeSubname}
                        label={"Фамилия"}
                    />
                    <InputText
                        inputValue={number}
                        setInputChange={handleChangeNumber}
                        placeholder={"Введите телефон"}
                        //bottomText={validationMassegeNumber}
                        label={"Тел +7"}
                    />
                    <InputText
                        inputValue={email}
                        setInputChange={handleChangeEmail}
                        placeholder={"Введите email"}
                        //bottomText={validationMassegeEmail}
                        label={"Email"}
                        isRequired={false}
                    />
                    <ButtonFileUpload
                        buttonText={'Выбрать Аватар'}
                        onFileChange={setAvatar}
                    />
                    {!trainer &&
                        <FormPaymentSetting
                            handleDataPayment={(data) => setDataPayment(data)}
                            handleFormValidate={(state) => setValidDataPayment(state)}
                        />}
                </>
            }
        />
    )
}

export default FormAddorUpdateTrainers;