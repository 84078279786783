import React from 'react';
import ClientInfo from '../../commonComponents/pages/ClientInfo/ClientInfo';
import FormAbonement from './components/forms/FormAbonement/FormAbonement';
import FormClient from './components/forms/FormClient/FormClient';
import FormRemoveVisitWithModal from './components/forms/FormRemoveVisitWithModal/FormRemoveVisitWithModal';
import ListVisits from './components/ListActiveVisits/ListVisits';
import Preloader from '../../commonComponents/loaders/Preloader/Preloader';
import useLogicClientPage from './logic/useLogicClientPage';
import ListClentAbonements from './components/ListClentAbonements/ListClentAbonements';
import ButtonNewAbonement from './components/ButtonNewAbonement.tsx';

/** Страница оображения информации о клиенте и его абонементе и посещениях */
function ClientPage() {
    const {
        client,
        openedForm, setOpenedForm,
        selectedAbonement,
        abonementType,
        abonementSettigsInputOptions,
        selectedTraining,
        openAbonement,
        abonements,
        fullAbonementList,
        handleSelectTraining,
        handleSuccessUpdate,
        handleSuccessRemoveVisit,
        isLoading,
        validMessage,
        closeFormAbonement,
    } = useLogicClientPage();

    return (
        <section className='client-page'>
            {client &&
                <ClientInfo
                    client={client}
                    handleClickOnAbonement={openAbonement}
                    handleClickOnClient={() => setOpenedForm('FormClient')}
                />}
            <ButtonNewAbonement openFormAbonement={openAbonement} />
            <div className='margin-top__30px'>
                {!isLoading ?
                    abonements.map((abonement) => {
                        return (
                            <ListVisits
                                key={abonement._id}
                                client={client}
                                groupeAbonement={abonement}
                                handleSelectTraining={handleSelectTraining}
                            />
                        )
                    })
                    : <Preloader />}
                {validMessage &&
                    <p>{validMessage}</p>}
            </div>
            {fullAbonementList && fullAbonementList.length > 0 &&
                <div className='margin-top__30px'>
                    <ListClentAbonements abonements={fullAbonementList} />
                </div>}
            {client &&
                <FormAbonement
                    type={abonementType}
                    isUpdate={false}
                    isOpen={openedForm === 'FormAbonement'}
                    isClose={closeFormAbonement}
                    abonement={selectedAbonement} // если передан - то редактирование 
                    abonementSettigsInputOptions={abonementSettigsInputOptions}
                    client={client}
                />}
            {client &&
                <FormClient
                    isOpen={openedForm === 'FormClient'}
                    closeForm={() => setOpenedForm()}
                    client={client}
                    handleSuccessUpdate={handleSuccessUpdate}
                />}
            {client &&
                <FormRemoveVisitWithModal
                    isOpen={openedForm === 'FormRemoveVisit'}
                    closeForm={() => setOpenedForm()}
                    client={client}
                    training={selectedTraining}
                    handleSuccessRemoveVisit={handleSuccessRemoveVisit}
                />
            }
        </section>
    )
}

export default ClientPage;