import { host } from "./const";

const apiTrainer = {
    host: host,

    createTrainer(data, avatar) {
        const formData = new FormData();
        // Добавляем все поля из объекта data в formData
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });
        if(avatar){
            formData.append('avatar', avatar);
        }

        return fetch(`${this.host}/trainer`,
            {
                method: 'POST',
                credentials: 'include',
                body: formData,
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    updateTrainer(data, avatar) {
        const formData = new FormData();
        // Добавляем все поля из объекта data в formData
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });
        if(avatar){
            formData.append('avatar', avatar);
        }

        return fetch(`${this.host}/trainer`,
            {
                method: 'PATCH',
                credentials: 'include',
                body: formData,
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    controlTrainerCompanyBranch({ companyBranchId, trainerId, action }) {
        return fetch(`${this.host}/trainer-company-branch`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ companyBranchId, trainerId, action })
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    getTrainersByCompanyId(companyId) {
        return fetch(`${this.host}/trainers-company/${companyId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    getTrainerById(companyBranchId, trainerId) {
        return fetch(`${this.host}/trainer-by-id/${companyBranchId}/${trainerId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    getTrainersByCompanyBranchId(branchId) {
        return fetch(`${this.host}/trainers-company-branch/${branchId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

}

export default apiTrainer;