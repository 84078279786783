import React from "react";
import ButtonWithBorder from "../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";
import useLogicClientPage from "../logic/useLogicClientPage";

const ButtonNewAbonement = ({openFormAbonement}) => {

    return (
        <div className="margin-top__30px">
            <ButtonWithBorder
                text={'Новый абонемент'}
                onClick={openFormAbonement}
            />
        </div>
    )
}

export default ButtonNewAbonement;