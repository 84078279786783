import { useEffect, useState } from "react"
import { useStudio } from "../../../../../context/studioContext"
import apiCompanyBranch from "../../../../../api/apiCompanyBranch"

function useLogicFormCompanyBranch() {
    const { studio } = useStudio();
    const [name, setName] = useState()
    const [wa, setWA] = useState()
    const [mediaType, setMediaType] = useState()
    const [media, setMedia] = useState()
    const [isLoading, setIsLoading] = useState(false)

    async function update() {
        const data = {
            companyBranchId: studio._id,
            name,
            wa,
            mediaType,
        }
        try {
            setIsLoading(false)
            console.log(data)
            await apiCompanyBranch.update(data, media)
            setIsLoading(false)
        }
        catch (err) {
            alert('Ошибка')
            console.log(err)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setName(studio.name)
        setWA(studio.wa)
        setMediaType(studio.mediaType)
        setMedia(studio.media)
    }, [studio])

    useEffect(() => {
        console.log(mediaType)
    }, [mediaType])

    return ({
        name, setName,
        wa, setWA,
        mediaType, setMediaType,
        setMedia,
        update,
        isLoading,
    })
}

export default useLogicFormCompanyBranch;