import { useState } from "react";

function useForm({
    setAbonementType,
    setSelectedAbonement,
}) {

    const [openedForm, setOpenedForm] = useState();

    function openAbonement(type, abonement) {
        if (abonement) {
            setSelectedAbonement(abonement)
        }
        setOpenedForm('FormAbonement')
    }

    return ({
        openedForm, setOpenedForm,
        openAbonement,
    })
}

export default useForm;