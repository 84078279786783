import React from 'react';

interface ImageUploadButtonProps {
  buttonText: string; // Текст на кнопке
  onFileChange: (file: File | null) => void; // Слушатель нажатия
  accept: string;
}

const ButtonFileUpload: React.FC<ImageUploadButtonProps> = ({ buttonText, onFileChange, accept = "image/*" }) => {

  const MAX_FILE_SIZE = 7 * 1024 * 1024; // 7 MB в байтах

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null; // Получаем первый выбранный файл или null

    if (file && file.size > MAX_FILE_SIZE) {
      alert('Размер файла не должен превышать 7 МБ'); // Показываем сообщение об ошибке
      onFileChange(null); // Передаем null, чтобы сбросить файл
      return;
    }

    onFileChange(file); // Вызываем переданный слушатель
  };

  return (
    <div
      className={`button-file_margin button-file`}
    >
      <label htmlFor="image-upload" style={{ cursor: 'pointer' }}>
        {buttonText}
      </label>
      <input
        type="file"
        accept={accept}
        id="image-upload"
        style={{ display: 'none' }} // Скрываем input
        onChange={handleChange}
      />
    </div>
  );
};

export default ButtonFileUpload;