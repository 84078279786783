import React from "react";
import FormAddOrUpdate from "../../../../../../../commonComponents/forms/FormAdd/FormAdd";
import InputText from "../../../../../../../commonComponents/inputs/InputText/InputText";
import InputSelect from "../../../../../../../commonComponents/inputs/InputSelect/InputSelect";
import InputDate from "../../../../../../../commonComponents/inputs/InputDate/InputDate";
import useLogicFormCreateOrUpdateAbonement from "./logic/useLogicFormCreateOrUpdateAbonement";
import { setStateForCustom } from "./logic/utils/validation";

function FormCreateOrUpdateAbonement({
    abonement,
    client,
    handleCreate,
    type,
    abonementSettigsInputOptions,
}) {

    const {
        abonementType,
        setAbonementType,
        dateOn,
        setDateOn,
        dateTo,
        setDateTo,
        quantity,
        setQuantity,
        cost,
        setCost,
        isValidForm,
        submitText,
        setSubmitText,
        isLoading,
        isDisabledInputs,
        //Функции
        onSubmit,
    } = useLogicFormCreateOrUpdateAbonement({
        abonement,
        client,
        handleCreate, //
        abonementSettigsInputOptions,
    });

    const nameForm = abonement ? abonement.type.customName : 'Новый';
    const nameSubmit = abonement ? 'Редактировать' : 'Создать';

    return (
        <FormAddOrUpdate
            text={nameForm} // заголовок формы
            textButtonSubmit={nameSubmit} // текст кнопки сабмита
            validationOptions={!isValidForm} // дисейбл
            textForFill={submitText}
            cleanTextForFill={() => setSubmitText(undefined)}
            isLoading={isLoading}
            onSubmit={onSubmit}
            children={
                <>
                    {/* ИМЯ Клиента */}
                    <InputText
                        label="Клиент"
                        inputValue={`${client.name} ${client.surname}`}
                        setInputChange={() => { }}
                        placeholder={'Имя Клиента'}
                    />
                    {/* Тип абонемента */}
                    <InputSelect
                        options={abonementSettigsInputOptions}
                        value={abonementType}
                        onChange={setAbonementType}
                        bottomText={''}
                        placeholder={'Тип абонемента'}
                    />
                    {/* Дата начала абонемента */}
                    <InputDate
                        label={'Начало'}
                        type={'date'}
                        value={dateOn.toString()}
                        onChange={setDateOn}
                        bottomText={''}
                    />
                    {/* Дата окончания абонемента */}
                    <InputDate
                        label={'Окончание'}
                        type={'date'}
                        value={dateTo.toString()}
                        onChange={setDateTo}
                        bottomText={''}
                        isRequired={false}
                    />
                    {/* Количество тренировок */}
                    <InputText
                        label={'Количество'}
                        inputValue={quantity}
                        setInputChange={setQuantity}
                        isDisabled={isDisabledInputs}
                    />
                    {/* Cтоимость */}
                    <InputText
                        label={'Стоимость'}
                        inputValue={cost}
                        setInputChange={setCost}
                        isDisabled={isDisabledInputs}
                    />
                </>
            }

        />
    )
}

export default FormCreateOrUpdateAbonement;