import { useEffect, useState } from "react";
import { sortByDate } from "../../../../utils/date";
import { useStudio } from "../../../../context/studioContext";
import apiAbonementSettings from "../../../../api/apiAbonementSettings";
import apiClient from "../../../../api/apiClient";

function useAbonements({
    client,
    setValidMessage,
    setIsLoading,
}) {
    const { studio } = useStudio();
    const [abonementSettigs, setAbonementSettigs] = useState([]);
    const [fullAbonementList, setFullAbonementList] = useState([]); // история абонемнтов
    const [abonements, setAbonements] = useState([]);
    const [groupeAbonement, setGropeAbonement] = useState();
    const [personalAbonement, setPersonalAbonement] = useState();
    const [abonementType, setAbonementType] = useState('группа');
    const [abonementSettigsInputOptions, setAbonementSettigsInputOptions] = useState([]);

    function fillAbonements(data) {
        setGropeAbonement(data.groupeAbonement)
        setPersonalAbonement(data.personalAbonement)
        client.groupeAbonement = data.groupeAbonement;
        let arrayAbonements = [];
        if (data.allGroupeAbonements) {
            arrayAbonements = arrayAbonements.concat(data.allGroupeAbonements)
        }
        if (data.allPersonalAbonements) {
            arrayAbonements = arrayAbonements.concat(data.allPersonalAbonements)
        }

        const sortedByData = sortByDate(arrayAbonements);
        return sortedByData;
    }

    async function fillAbonementSettings() {
        try {
            const data = await apiAbonementSettings.getAbonementSettingsByCompanyBranchId(studio._id);
            setAbonementSettigs(data.abonementSettings)
        }
        catch (err) {
            console.log(err);
        }
    }

    function fillAbonementsSettingsOptions() {
        if (abonementSettigs) {
            const options = abonementSettigs.map((setting) => {
                const label = `${setting.customName}`
                const obj = { value: setting._id, label, setting: setting }
                return obj;
            })
            setAbonementSettigsInputOptions(options)
        }
    }

    async function fillFullAbonements() {
        const res = await apiClient.getClientAbonements({ clientId: client._id, companyBranchId: studio._id })
        setFullAbonementList(res);
    }

    async function fill() {
        if (client._id && studio._id) {
            try {
                setValidMessage();
                setIsLoading(true);
                const data = await apiClient.getClientById(client._id, studio._id);
                fillAbonementSettings()
                fillFullAbonements()
                setAbonements(data.abonements);
                setIsLoading(false)
            }
            catch (err) {
                setIsLoading(false);
                setValidMessage(err.message)
            }
        }
    }

    useEffect(() => {
        if (client && studio) {
            fill()
        }
    }, [client, studio])

    useEffect(() => {
        fillAbonementsSettingsOptions()
    }, [abonementType, abonementSettigs])

    return ({
        abonementSettigs, setAbonementSettigs,
        abonements, setAbonements,
        fullAbonementList,
        groupeAbonement, setGropeAbonement,
        personalAbonement, setPersonalAbonement,
        abonementType, setAbonementType,
        abonementSettigsInputOptions,
        fill,
    })
}

export default useAbonements;