import React from "react";
import FormCompanyBranch from "./components/FormCompanyBranch/FormCompanyBranch";

/** @page страница настройки филила */
const CompanyBranchSettingPage = () => {
    return (
        <div>
            <FormCompanyBranch />
        </div>
    )
}

export default CompanyBranchSettingPage;