import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonWithBorder from "../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";

/** Страница выбора настроек абонементов и тренировок */
function SettingPage({ }) {

    const navigate = useNavigate();

    function navigateTrainingSetting() {
        navigate('/training-setting')
    }

    function navigateAbonementSetting() {
        navigate('/abonement-setting')
    }

    function navigateCompanyBranchSetting(){
        navigate('/company-branch-setting')
    }

    return (
        <div className="setting-page">
            <div className="">
                <p className="formadd__title">{'Настроить'}</p>
                <div className="gap__17px margin-top__30px">
                    <ButtonWithBorder text={'Тренировки'} onClick={navigateTrainingSetting}/>
                    <ButtonWithBorder text={'Абонементы'} onClick={navigateAbonementSetting} />
                    <ButtonWithBorder text={'Филиал'} onClick={navigateCompanyBranchSetting} />
                </div>
            </div>
        </div>
    )
}

export default SettingPage;