import apiTraining from "../../../../api/apiTraining";

function getOnAndToDates(datesArray) {
    if (!Array.isArray(datesArray) || datesArray.length === 0) {
        return null;
    }

    const dateOn = new Date(datesArray[0]); // Копируем первую дату массива

    // Устанавливаем время на начало дня (00:00:00)
    dateOn.setHours(0, 0, 0, 0);

    let dateTo;

    if (datesArray.length === 1) {
        dateTo = new Date(dateOn); // Копируем начальную дату
    } else {
        dateTo = new Date(datesArray[datesArray.length - 1]); // Копируем последнюю дату массива
    }

    // Устанавливаем время на конец дня (23:59:59)
    dateTo.setHours(23, 59, 59, 999);

    return { dateOn: dateOn.toString(), dateTo: dateTo.toString() };
}

function getCurrentWeekDates() {
    const currentDate = new Date(); // Текущая дата
    const currentDayOfWeek = currentDate.getDay(); // День недели (0 - воскресенье, 1 - понедельник, ..., 6 - суббота)

    const mondayDate = new Date(currentDate); // Понедельник текущей недели
    mondayDate.setDate(currentDate.getDate() - currentDayOfWeek + (currentDayOfWeek === 0 ? -6 : 1)); // Если текущий день - воскресенье, то понедельник прошлой недели

    const weekDates = []; // Массив для хранения дат текущей недели

    // Добавляем даты с понедельника по воскресенье
    for (let i = 0; i < 7; i++) {
        const date = new Date(mondayDate);
        date.setDate(mondayDate.getDate() + i);
        weekDates.push(date);
    }

    return weekDates;
}

function formateLocaleDateTrainings(trainings) {
    const formatedLocaleDateTrainings = trainings.map((training) => {
        const localDateOn = new Date(training.dateOn);
        const localDateTo = new Date(training.dateTo);
        training.dateOn = localDateOn;
        training.dateTo = localDateTo;
        return training;
    })
    return formatedLocaleDateTrainings;
}


async function getTrainingsByDateRange({ dateRange, companyBranchId, isLoading }) {
    try {
        const range = dateRange ? dateRange : getCurrentWeekDates();
        const { dateOn, dateTo } = getOnAndToDates(range);
        const trainings = await apiTraining.getTrainingsByCompanyBranchIdAndDateRange({ companyBranchId, dateOn, dateTo })
        const formatedTrainings = formateLocaleDateTrainings(trainings)
        return formatedTrainings;
    }
    catch (err) {
        console.log(err)
    }
}

function formateTrainingForCalendar(training) {
    //Инф о тренере
    let trainerName;
    if (training.trainer !== null && training.trainer !== undefined) {
        const firstLetter = training.trainer.surname[0];
        trainerName = `${training.trainer.name} ${firstLetter}`;
    } else {
        trainerName = 'тренер'
    }
    const name = `${training.name}`;
    const filling = `${training.listOfVisits.length} из ${training.capacity}`
    const start = new Date(training.dateOn);
    const end = new Date(training.dateTo);

    return {
        id: training._id,
        title: name,
        trainerName,
        filling,
        start: start,
        end: end,
    }
}

function fomateTrainingsForCalndar(list) {
    const events = list.map(training => {
        const formatedTraining = formateTrainingForCalendar(training);
        return formatedTraining;
    });
    return events;
}
function filterTrainingsByCompayHallId({ trainings, hallId }) {
    const filtredTrainings = trainings.filter((training) => training.space._id === hallId);
    return filtredTrainings;
}

function getTrainingsForCalendarByHallId({ trainings, hallId }) {
    const filtredTrainings = filterTrainingsByCompayHallId({ trainings, hallId });
    const formatedTrainings = fomateTrainingsForCalndar(filtredTrainings)
    return formatedTrainings;
}


export {
    getTrainingsByDateRange,
    fomateTrainingsForCalndar,
    getTrainingsForCalendarByHallId,
    formateTrainingForCalendar,
}