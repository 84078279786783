import { useStudio } from "../../../context/studioContext";
import AbonementNameAndIndicator from "../AbonementNameAndIndicator/AbonementNameAndIndicator";

/** Информация о клиенте и его абонементе */
function ClientInfo({ client, handleClickOnAbonement = () => { }, handleClickOnClient = () => { }, fullInfo = true }) {
    const { objStyles } = useStudio();

    const phoneNumber = client.contactData?.phoneNumber ? client.contactData.phoneNumber : '';
    return (
        <div className='client-info'>
            <p className={`client-info__text font-weight-bold ${objStyles.colorText}`} onClick={handleClickOnClient ? () => handleClickOnClient(client) : () => { }}>
                {client.surname} {client.name}
            </p>
            {phoneNumber && fullInfo &&
                <p className={`client-info__text font-weight-medium ${objStyles.colorText}`}>
                    {phoneNumber}
                </p>}
            {fullInfo &&
                client.abonements &&
                client.abonements.map((abonement) => {
                    return (
                        <AbonementNameAndIndicator
                            key={abonement._id}
                            abonement={abonement}
                            handleClickOnAbonement={handleClickOnAbonement ? () => handleClickOnAbonement(abonement.type, abonement) : () => { }}
                        />
                    )
                })
            }

        </div>
    )
}

export default ClientInfo;